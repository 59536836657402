import "./SearchBar.scss"

import React, { useState, useEffect } from "react"

import { mdiMagnify } from "@mdi/js"
import Icon from "@mdi/react"

const SearchBar = props => {
  const { placeholder, searchValue, updateSearchValue } = props

  const [keyword, setKeyword] = useState(searchValue)

  useEffect(() => {
    setKeyword(searchValue)
  }, [searchValue])

  const handleKeyPress = event => {
    if (event.key === "Enter") {
      updateSearchValue(keyword)
    }
  }

  const barBgColor =
    searchValue && searchValue.length ? "bg-mint" : "bg-bar-grey"

  return (
    <div className="searchBar d-flex align-items-center">
      <div className="icon position-absolute mx-3">
        <Icon path={mdiMagnify} size={1} />
      </div>
      <input
        className={` softer-corners px-5 ${barBgColor}`}
        type="text"
        name="name"
        placeholder={placeholder}
        value={keyword}
        onChange={e => setKeyword(e.target.value)}
        onKeyPress={handleKeyPress}
      />
    </div>
  )
}
export default SearchBar
