import "./glossary.scss"

import React, { useState } from "react"

import { StaticImage } from "gatsby-plugin-image"

import Button from "../components/Button"
import Dash from "../components/Dash"
import Layout from "../components/Layout"
import SearchBar from "../components/SearchBar"
import glossaryHeaderBg from "../images/glossary/bg.webp"
import { getAllLocales, getCurrentLanguagePage } from "../plugins/common"

const simplePageShape_left = "../images/simplePage/simplePageShape_left.webp"
const simplePageShape_right = "../images/simplePage/simplePageShape_right.webp"
const faqLeftShapes = "../images/faq/faqLeftShapes.webp"
const faqRightEmpty = "../images/faq/faqRightEmpty.webp"

const GlossaryPage = ({ pageContext, location }) => {
  const { data, general, form } = pageContext
  const {
    title,
    subtitle,
    enableSearchByAlphabet,
    glossary,
    glossaryTranslation,
    meta,
    localizations,
    locale,
  } = data
  //   console.log(pageContext.data)

  const aleaToken = general.aleaToken
  const alphabets = "abcdefghijklmnopqrstuvwxyz"
  const languageOptions = general.allStrapiLanguages?.nodes ?? "en"
  const lang = getCurrentLanguagePage(location, languageOptions)
  const enableFooterForm = data.footerForm?.enableFooter
  const footerForm = {
    ...data.footerForm,
    ...form.freeQuote,
  }

  const [keyword, setKeyword] = useState("")
  const [alphabet, setAlphabet] = useState("")
  const [rule, setRule] = useState(0)
  const [limit, setLimit] = useState(50)

  const filterByKeyword = val => {
    setKeyword(val)
    setRule(1)
  }
  const filterByAlphabet = val => {
    if (val === alphabet) {
      setAlphabet("")
      setRule(0)
      return
    }

    setAlphabet(val)
    setRule(2)
  }
  const loadMore = () => {
    setLimit(limit + 50)
  }
  const filterByRule = item => {
    return rule === 0
      ? item
      : rule === 1
      ? item.title.toLowerCase().includes(keyword.toLowerCase()) ||
        item.content.toLowerCase().includes(keyword.toLowerCase())
      : item.title.toLowerCase().startsWith(alphabet.toLowerCase())
  }
  const clearFilter = () => {
    setAlphabet("")
    setKeyword("")
    setRule(0)
  }

  return (
    <Layout
      props={general}
      meta={meta}
      localizations={getAllLocales(localizations, locale)}
      location={location}
      lang={lang}
      hideNewsletter={true}
      token={aleaToken}
      footerForm={enableFooterForm ? footerForm : null}
    >
      <div className="glossary">
        <div
          className="container-fluid text-center header position-relative"
          style={{ backgroundImage: `url(${glossaryHeaderBg})` }}
        >
          <StaticImage
            sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
            className="left position-absolute"
            src={simplePageShape_left}
            alt=""
            layout="constrained"
          />
          <StaticImage
            sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
            className="right position-absolute"
            src={simplePageShape_right}
            alt=""
            layout="constrained"
          />
          <h1 className="ming">{title}</h1>
        </div>
        <div className="position-relative faq-body">
          <StaticImage
            sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
            className="left position-absolute"
            src={faqLeftShapes}
            alt=""
            layout="constrained"
          />
          <StaticImage
            sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
            className="right position-absolute"
            src={faqRightEmpty}
            alt=""
            layout="constrained"
          />
          <div className="container content card soft-corners soft-shadows">
            <div className="row d-flex flex-lg-row flex-column">
              <div className="col-lg-8 col-12 order-lg-0 order-1 left-col">
                <div className="subtitle">
                  <h2 className=" ming">{subtitle}</h2>
                  <Dash />
                </div>

                <div className="glossary-list">
                  {glossary
                    .filter(item => filterByRule(item))
                    .slice(0, limit)
                    .sort((a, b) => {
                      if (a.title < b.title) return -1
                      if (a.title > b.title) return 1
                      return 0
                    })
                    .map((item, index) => (
                      <div key={index} className="glossary-item">
                        <h3 className="teal">{item.title}</h3>
                        <p>{item.content}</p>
                      </div>
                    ))}
                  {limit <
                    glossary.filter(item => filterByRule(item)).length && (
                    <div className="loadMore text-center">
                      <Button
                        type="primary"
                        text={glossaryTranslation.loadMoreLabel}
                        event={loadMore}
                      />
                    </div>
                  )}
                  {glossary.filter(item => filterByRule(item)).length === 0 && (
                    <div className="noresult">
                      {glossaryTranslation.noResultLabel}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-4 col-12 order-lg-1 order-0 sideBar">
                <div className="searchBar">
                  <SearchBar
                    placeholder={glossaryTranslation.searchKeywordsLabel}
                    searchValue={keyword}
                    updateSearchValue={filterByKeyword}
                  />
                </div>
                {enableSearchByAlphabet && (
                  <div className="alphabet-filter container">
                    <div className="filter-header">
                      <h3 className="openSans-Bold ming label">
                        {glossaryTranslation.filterByAlphabetLabel}
                      </h3>
                      <Button
                        type="getGuide"
                        text={glossaryTranslation.clearLabel}
                        event={clearFilter}
                      />
                    </div>

                    <div className="alphabet-list row">
                      {alphabets.split("").map((item, index) => (
                        <div
                          role="button"
                          tabIndex={0}
                          key={index}
                          className={`alphabet ming col-2 ${
                            rule === 2 && alphabet === item ? "selected" : ""
                          }`}
                          onClick={() => {
                            filterByAlphabet(item)
                          }}
                          onKeyDown={() => {
                            filterByAlphabet(item)
                          }}
                        >
                          {item.toUpperCase()}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default GlossaryPage
